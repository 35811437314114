import React from "react";
// import sections

const PrivacyPolicy = () => {
  return (
    <>
      <section className="section">
        <div className="container">
          <p>
            Thank you for choosing to be part of our community at Ecom
            Brand Builder (“Company”, “we”, “us”, or “our”). We are committed to
            protecting your personal information and your right to privacy. If
            you have any questions or concerns about this privacy notice, or our
            practices with regards to your personal information, please contact
            us at contact@ecombrandbuilder.com.
          </p>
          <p>
            When you visit our website ecombrandbuilder.com (the “Website“), and more
            generally, use any of our services (the “Services“, which include
            the Website), we appreciate that you are trusting us with your
            personal information. We take your privacy very seriously. In this
            privacy notice, we seek to explain to you in the clearest way
            possible what information we collect, how we use it and what rights
            you have in relation to it. We hope you take some time to read
            through it carefully, as it is important. If there are any terms in
            this privacy notice that you do not agree with, please discontinue
            use of our Services immediately.
          </p>
          <p>
            This privacy notice applies to all information collected through our
            Services (which, as described above, includes our Website), as well
            as any related services, sales, marketing or events.
          </p>
          <p>
            <b>
              Please read this privacy notice carefully as it will help you
              understand what we do with the information that we collect.
            </b>
          </p>
          <p>
            <b>1. WHAT INFORMATION DO WE COLLECT?</b>
          </p>
          <p>
            <b>Personal information you disclose to us</b>
          </p>
          <p>
            In Short:  We collect information that you provide to us. We collect
            personal information that you voluntarily provide to us when
            you register on the Website, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Website or otherwise when you
            contact us.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Website, the choices you make and
            the products and features you use. The personal information we
            collect may include the following:{" "}
          </p>
          <p>
            <b>Personal Information Provided by You</b> We collect names; email
            addresses; debit/credit card numbers; contact
            preferences; passwords; usernames; and other similar information.
          </p>
          <p>
            <b>Payment Data.</b> We may collect data necessary to process your
            payment if you make purchases, such as your payment instrument
            number (such as a credit card number), and the security code
            associated with your payment instrument. All payment data is stored
            by Stripe. You may find their privacy notice link(s)
            here: https://stripe.com/privacy.{" "}
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information. Information automatically collected
          </p>
          <p>
            In Short:  Some information — such as your Internet Protocol (IP)
            address and/or browser and device characteristics — is collected
            automatically when you visit our Website.
          </p>
          <p>
            We automatically collect certain information when you visit, use or
            navigate the Website. This information does not reveal your specific
            identity (like your name or contact information) but may include
            device and usage information, such as your IP address, browser and
            device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about
            who and when you use our Website and other technical information.
            This information is primarily needed to maintain the security and
            operation of our Website, and for our internal analytics and
            reporting purposes.
          </p>
          <p>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </p>
          <p>The information we collect includes:</p>
          <ul>
            <li>
              Log and Usage Data. Log and usage data is service-related,
              diagnostic usage and performance information our servers
              automatically collect when you access or use our Website and which
              we record in log files. Depending on how you interact with us,
              this log data may include your IP address, device information,
              browser type and settings and information about your activity in
              the Website (such as the date/time stamps associated with your
              usage, pages and files viewed, searches and other actions you take
              such as which features you use), device event information (such as
              system activity, error reports (sometimes called ‘crash dumps’)
              and hardware settings).
            </li>
            <li>
              Device Data. We collect device data such as information about your
              computer, phone, tablet or other device you use to access
              the Website. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device application identification numbers, location, browser type,
              hardware model Internet service provider and/or mobile carrier,
              operating system configuration information.
            </li>
          </ul>
          <p>
            <b>2. HOW DO WE USE YOUR INFORMATION?</b>
          </p>
          <p>
            In Short:  We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent. We
            use personal information collected via our Website for a variety of
            business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </p>
          <p>We use the information we collect or receive:</p>
          <ul>
            <li>
              <b>Fulfill and manage your orders</b>. We may use your information
              to fulfill and manage your orders, payments, returns, and
              exchanges made through the Website.
            </li>
            <li>
              <b>Administer prize draws and competitions</b>. We may use your
              information to administer prize draws and competitions when you
              elect to participate in our competitions.
            </li>
            <li>
              <b>To deliver and facilitate delivery of services to the user</b>{" "}
              . We may use your information to provide you with the requested
              service.
            </li>
            <li>
              <b>To respond to user inquiries/offer support to users</b> . We
              may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
            </li>
          </ul>
          <p>
            <b>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</b>
          </p>
          In Short:  We only share information with your consent, to comply with
          laws, to provide you with services, to protect your rights, or to
          fulfill business obligations.
          <p>
            We may process or share your data that we hold based on the
            following legal basis:
          </p>
          <ul>
            <li>
              <b>Consent</b>: We may process your data if you have given us
              specific consent to use your personal information in a specific
              purpose.
            </li>
            <li>
              <b>Legitimate Interests</b>: We may process your data when it is
              reasonably necessary to achieve our legitimate business interests.
            </li>
            <li>
              <b>Performance of a Contract</b>: Where we have entered into a
              contract with you, we may process your personal information to
              fulfill the terms of our contract.
            </li>
            <li>
              <b>Legal Obligations</b>: We may disclose your information where
              we are legally required to do so in order to comply with
              applicable law, governmental requests, a judicial proceeding,
              court order, or legal process, such as in response to a court
              order or a subpoena (including in response to public authorities
              to meet national security or law enforcement requirements).
            </li>
            <li>
              <b>Vital Interests</b>: We may disclose your information where we
              believe it is necessary to investigate, prevent, or take action
              regarding potential violations of our policies, suspected fraud,
              situations involving potential threats to the safety of any person
              and illegal activities, or as evidence in litigation in which we
              are involved.
            </li>
          </ul>
          <p>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </p>
          <ul>
            <li>
              <li>
                <b>Business Transfers</b> . We may share or transfer your
                information in connection with, or during negotiations of, any
                merger, sale of company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
            </li>
          </ul>
          <p>
            <b>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b>
          </p>
          <p>
            In Short:  We may use cookies and other tracking technologies to
            collect and store your information. 
          </p>
          <p>We may use cookies and similar
            tracking technologies (like web beacons and pixels) to access or
            store information. Specific information about how we use such
            technologies and how you can refuse certain cookies is set out in
            our Cookie Notice.</p>
          <p>
            <b>5. HOW LONG DO WE KEEP YOUR INFORMATION?</b>
          </p>
          <p>
            In Short:  We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy notice unless
            otherwise required by law. 
          </p>
          <p>We will only keep your personal
            information for as long as it is necessary for the purposes set out
            in this privacy notice, unless a longer retention period is required
            or permitted by law (such as tax, accounting or other legal
            requirements). No purpose in this notice will require us keeping
            your personal information for longer than the period of time in
            which users have an account with us. </p>
            <p>When we have no ongoing
            legitimate business need to process your personal information, we
            will either delete or anonymize such information, or, if this is not
            possible (for example, because your personal information has been
            stored in backup archives), then we will securely store your
            personal information and isolate it from any further processing
            until deletion is possible.</p>
          <p>
            <b>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</b>
          </p>
          <p>In Short:  We aim to protect your personal information through a
          system of organizational and technical security measures. </p>
          <p>We have
          implemented appropriate technical and organizational security measures
          designed to protect the security of any personal information we
          process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our Website is at
          your own risk. You should only access the Website within a secure
          environment.</p>
          <p>
            <b>7. WHAT ARE YOUR PRIVACY RIGHTS?</b>
          </p>
          <p>
            In Short:  In some regions, such as the European Economic Area, you
            have rights that allow you greater access to and control over your
            personal information. You may review, change, or terminate your
            account at any time. 
          </p>
          <p>In some regions (like the European Economic
            Area), you have certain rights under applicable data protection
            laws. These may include the right (i) to request access and obtain a
            copy of your personal information, (ii) to request rectification or
            erasure; (iii) to restrict the processing of your personal
            information; and (iv) if applicable, to data portability. In certain
            circumstances, you may also have the right to object to the
            processing of your personal information. To make such a request,
            please use the contact details provided below. We will consider and
            act upon any request in accordance with applicable data protection
            laws. </p>
            <p>If we are relying on your consent to process your personal
            information, you have the right to withdraw your consent at any
            time. Please note however that this will not affect the lawfulness
            of the processing before its withdrawal, nor will it affect the
            processing of your personal information conducted in reliance on
            lawful processing grounds other than consent. </p>
            <p>If you are resident in
            the European Economic Area and you believe we are unlawfully
            processing your personal information, you also have the right to
            complain to your local data protection supervisory authority. You
            can find their contact details
            here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </p>
            <p>If you are resident in Switzerland, the contact details for the data
            protection authorities are available
            here: https://www.edoeb.admin.ch/edoeb/en/home.html. </p>
            <p>If you have
            questions or comments about your privacy rights, you may email us
            at contact@ecombrandbuilder.com.</p>
          <p>Account Information</p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
           
          </ul>
          <p>Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements. </p>
              <p><b>Cookies and similar technologies:</b> Most Web browsers
              are set to accept cookies by default. If you prefer, you can
              usually choose to set your browser to remove cookies and to reject
              cookies. If you choose to remove cookies or reject cookies, this
              could affect certain features or services of our Website. To
              opt-out of interest-based advertising by advertisers on
              our Website visit http://www.aboutads.info/choices/.</p>
              <p><b>Opting out of
              email marketing</b>: You can unsubscribe from our marketing email list
              at any time by clicking on the unsubscribe link in the emails that
              we send or by contacting us using the details provided below. You
              will then be removed from the marketing email list – however, we
              may still communicate with you, for example to send you
              service-related emails that are necessary for the administration
              and use of your account, to respond to service requests, or for
              other non-marketing purposes. To otherwise opt-out, you may:</p>
          <p>
            <ul>
            <li>Contact us using the contact information provided.</li>
            <li>Access your account settings and update your preferences.</li>
            </ul>
            <b>8. CONTROLS FOR DO-NOT-TRACK FEATURES</b>
          </p>
          <p>Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage, no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.</p>
          <p>
            <b>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</b>
          </p>
          <p>
            In Short:  Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </p>
          <p>California Civil Code Section 1798.83, also known as the “Shine The
            Light” law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below. </p>
            <p>If you are under 18 years of age, reside
            in California, and have a registered account with the Website, you
            have the right to request removal of unwanted data that you publicly
            post on the Website. To request removal of such data, please contact
            us using the contact information provided below, and include the
            email address associated with your account and a statement that you
            reside in California. We will make sure the data is not publicly
            displayed on the Website, but please be aware that the data may not
            be completely or comprehensively removed from all our systems (e.g.
            backups, etc.). </p>
            <p><b>Will your information be shared with anyone else?</b></p>
          <p>
            We may use your personal information for our own business purposes,
            such as for undertaking internal research for technological
            development and demonstration. This is not considered to be
            “selling” of your personal data.
          </p>
          <p>
            ecombrandbuilder has not disclosed or sold any personal information to
            third parties for a business or commercial purpose in the preceding
            twelve (12) months. ecombrandbuilder will not sell personal information
            in the future belonging to website visitors, users and other
            consumers.
          </p>
          <p><b>Your rights with respect to your personal data</b></p>
          <p><u>Right to request deletion of the data – Request to delete</u></p>
          <p>
            You can ask for the deletion of your personal information. If you
            ask us to delete your personal information, we will respect your
            request and delete your personal information, subject to certain
            exceptions provided by law, such as (but not limited to) the
            exercise by another consumer of his or her right to free speech, our
            compliance requirements resulting from a legal obligation or any
            processing that may be required to protect against illegal
            activities.
          </p>
          <p><u>Right to be informed – Request to know</u></p>
          <p>Depending on the circumstances, you have a right to know:</p>
          <ul>
            <li>whether we collect and use your personal information;</li>
            <li>the categories of personal information that we collect;</li>
            <li>
              the purposes for which the collected personal information is used;
            </li>
            <li>whether we sell your personal information to third parties;</li>
            <li>
              the categories of personal information that we sold or disclosed
              for a business purpose;
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </li>
            <li>
              the business or commercial purpose for collecting or selling
              personal information.
            </li>
          </ul>
          <p>
            In accordance with applicable law, we are not obligated to provide
            or delete consumer information that is de-identified in response to
            a consumer request or to re-identify individual data to verify a
            consumer request.
          </p>
          <p><u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights</u></p>
          <p>
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p><u>Verification process</u></p>
          <p>
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with the information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p>
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. If, however, if we cannot verify
            your identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity, and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p><u>Other privacy rights</u></p>
          <ul>
            <li>you may object to the processing of your personal data</li>
            <li>
              you may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the data
            </li>
            <li>
              you can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>
            <li>
              you may request to opt-out from future selling of your personal
              information to third parties. Upon receiving a request to opt-out,
              we will act upon the request as soon as feasibly possible, but no
              later than 15 days from the date of the request submission.
            </li>
          </ul>
          <p>
            To exercise these rights, you can contact us by email
            at contact@ecombrandbuilder.com, or by referring to the contact details at
            the bottom of this document. If you have a complaint about how we
            handle your data, we would like to hear from you.
          </p>
          <p>
            <b>10. DO WE MAKE UPDATES TO THIS NOTICE?</b>
          </p>
          <p>
            In Short:  Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated “Revised” date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
          <p>
            <b>11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</b>
          </p>
          <p>
            If you have questions or comments about this notice, you may email
            us at contact@ecombrandbuilder.com or by post to:
          </p>
          <p>ecombrandbuilder</p>
          <p>
            <b>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</b>
          </p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information,
          please visit: <a href="https://ecombrandclub.com/policies/privacy-policy">https://ecombrandclub.com/policies/privacy-policy</a>. We will respond to
          your request within 30 days.
          <p></p>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
